import React from 'react';

import NewsArticle from '../../../components/news-article/news-article.component'

const Article = () => (
  <NewsArticle>
    <h1 className='page-title'>HSBC UK cuts 20 mortgage rates and increases lending limits</h1>
    <p>HSBC UK has lowered 20 mortgage rates, with cuts of up to 0.1% across its range of LTVs taking place immediately, the bank announced today. HSBC UK also confirmed it has increased its maximum lending on higher LTV mortgages.</p>
    <p>The reduced mortgages rates include:</p>
    <ul>
      <li>85% LTV 5 year fixed rate (£999 fee) down 0.10% to 2.74%, with the fee saver equivalent reduced by 0.1% to 3.04%;</li>
      <li>85% LTV 2 year fixed rate (fee saver) lowered by 0.05% to 2.79%;</li>
      <li>75% LTV 2 year fixed rate (£999 fee) cut by 0.10% to 1.39%;</li>
      <li>60% LTV 5 year fixed rate (£999 fee) down by 0.05% to 1.29%; and</li>
      <li>90% LTV 2 year fixed rate (£999 fee) down by 0.05% to 3.09%, with the fee saver equivalent reduced by 0.05% to 3.29%.</li>
    </ul>
    <p>HSBC UK has also increased the lending limits on its 85% LTV and 90% LTV capital repayment mortgages, with the maximum borrowing on 85% LTV mortgage increased by 50% to £750k, and from £400k to £550k on 90% LTV borrowing.</p>
    <p>Michelle Andrews, HSBC UK’s Head of Buying a Home, said: “There are lots of different elements to consider when looking for a mortgage to get onto or up the property ladder. Two big considerations are interest rate and how much you can borrow, and these changes will make a big difference.</p>
    <p>“Alongside other changes we have recently made, including accepting variable pay as part of a mortgage application, we are excited about continuing to support homeowners and the wider property market.”</p>
  </NewsArticle>
)

export default Article
